import React, { ReactElement, ReactNode, useMemo } from "react"
import { Transaction } from "../../domain/models"
import { TransactionState } from "../common/transaction-state"
import * as styles from "./transaction-widget.module.scss"

interface Props {
  transaction: Transaction
  children?: ReactNode
}

export const TransactionWidget = React.memo((props: Props): ReactElement => {
  const { transaction, children } = props

  return (
    <>
      {!transaction.latestEvent?.requiresAction && (
        <div className={styles.stateContainer}>
          <TransactionState transaction={transaction} />
        </div>
      )}
      <Header transaction={transaction} />
      <OverviewTable transaction={transaction} />
      {transaction.fee != null && transaction.fee > 0 && (
        <Fee fee={transaction.fee} />
      )}
      <Footer transaction={transaction} />
      {children}
    </>
  )
})

const Header = ({
  transaction,
}: {
  transaction: Transaction
}): ReactElement => {
  const paymentFailureFee = useMemo(() => {
    if (
      transaction.latestEvent?.isPaymentProcessingResultAutoFailure !== true
    ) {
      return 0
    }
    return transaction.fee || 0
  }, [transaction])

  return (
    <>
      {transaction.latestEvent?.requiresAction && (
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>入庫のご確認をお願いします。</div>
          <p>
            入庫を検知しました。駐車場・車室をご確認の上、以下のボタンから入庫の確認をお願い致します。
          </p>
          <p>
            入庫確認を行うことで、事前に精算することなく出庫するだけで自動的に精算することができます。
          </p>
          <p>予約車室に他車が停めた場合、通知が送られる可能性があります。</p>
        </div>
      )}
      {paymentFailureFee > 0 && (
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>精算が失敗しました。</div>
          <p>
            精算料金({paymentFailureFee.toLocaleString("ja-JP")}
            円)の自動精算を登録されたクレジットカードで行ったところ失敗しました。
          </p>
          <p>
            後日ご登録いただいている住所宛に請求書をお送りさせていただきますのでお支払いをお願いいたします。
          </p>
        </div>
      )}
    </>
  )
}

const OverviewTable = ({
  transaction,
}: {
  transaction: Transaction
}): ReactElement => {
  const reservedTime = transaction.reserved?.createTime
  const reservationCanceledTime = transaction.reservationCanceled?.createTime
  const reservationExpiredTime = transaction.reservationExpired?.createTime
  const enteredTime =
    transaction.entryDetected?.createTime || transaction.entered?.createTime
  const exitedTime = transaction.exited?.createTime

  return (
    <div className={styles.overviewTableContainer}>
      <p className={styles.parkingLocation}>
        {transaction.parking?.displayName || ""}
      </p>
      <p className={styles.roomNumber}>
        車室番号: {transaction.parkingSpaceNumber}
      </p>
      <p className={styles.address}>{transaction.parking?.fullAddress || ""}</p>
      {reservedTime && <DateRow title="予約時刻" date={reservedTime} />}
      {reservationCanceledTime && (
        <DateRow title="キャンセル時刻" date={reservationCanceledTime} />
      )}
      {reservationExpiredTime && (
        <DateRow title="予約失効時刻" date={reservationExpiredTime} />
      )}
      {enteredTime && <DateRow title="入庫時刻" date={enteredTime} />}
      {exitedTime && <DateRow title="出庫時刻" date={exitedTime} />}
    </div>
  )
}

const DateRow = ({
  title,
  date,
}: {
  title: string
  date: Date
}): ReactElement => {
  const format = React.useCallback(
    date =>
      new Date(date).toLocaleString("ja-JP", {
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }),
    []
  )

  return (
    <div className={styles.date}>
      <span className={styles.dateTitle}>{title}</span>
      {format(date)}
    </div>
  )
}

const Fee = ({ fee }: { fee: number }): ReactElement => {
  return (
    <div className={styles.feeContainer}>
      <div>精算料金</div>
      <div className={styles.fee}>
        <span>{fee.toLocaleString("ja-JP")}</span>
        <span className={styles.feeSuffix}>円</span>
      </div>
    </div>
  )
}

const Footer = ({
  transaction,
}: {
  transaction: Transaction
}): ReactElement => {
  return (
    <>
      {transaction.latestEvent?.requiresAction && (
        <div className={styles.footerContainer}>
          <ul>
            <li>
              入庫確認を行うことで、事前に精算することなく出庫するだけで自動的に精算することができます。
            </li>
            <li>
              予約車室に他車が停めた場合、通知が送られる可能性があります。
            </li>
          </ul>
        </div>
      )}
    </>
  )
}
