// extracted by mini-css-extract-plugin
export var stateContainer = "transaction-widget-module--stateContainer--3mIn0";
export var headerContainer = "transaction-widget-module--headerContainer--2g23i";
export var headerTitle = "transaction-widget-module--headerTitle--gfa81";
export var overviewTableContainer = "transaction-widget-module--overviewTableContainer--3ZPpM";
export var parkingLocation = "transaction-widget-module--parkingLocation--FOkDI";
export var roomNumber = "transaction-widget-module--roomNumber--1w9Um";
export var address = "transaction-widget-module--address--3RSP9";
export var date = "transaction-widget-module--date--34Lrg";
export var dateTitle = "transaction-widget-module--dateTitle--AZeF6";
export var feeContainer = "transaction-widget-module--feeContainer--gAJvy";
export var fee = "transaction-widget-module--fee--1esLW";
export var feeSuffix = "transaction-widget-module--feeSuffix--3eWdY";
export var footerContainer = "transaction-widget-module--footerContainer--1kQwv";