import React, { ReactElement } from "react"
import { Transaction } from "../../domain/models"
import { TransactionEventType } from "../../types/transaction-event-type"
import * as styles from "./transaction-state.module.scss"

interface Props {
  transaction: Transaction
}

const displayTextsByEvent: Record<TransactionEventType, string> = {
  reserved: "予約中",
  reservationCanceled: "キャンセル済み",
  reservationExpired: "期限切れ",
  entryDetected: "入庫済み",
  entered: "入庫済み",
  entryAccepted: "入庫確認済み",
  entryRejected: "入庫キャンセル済み",
  exited: "出庫済み",
  aborted: "利用取消済み",
  paid: "精算済み",
}

export const TransactionState = React.memo((props: Props): ReactElement => {
  const { transaction } = props

  const latestEvent = transaction.latestEvent
  if (latestEvent == null) {
    return <></>
  }
  if (latestEvent.isPaymentProcessingResultAutoFailure) {
    return <div className={styles.failure}>精算失敗</div>
  }

  return (
    <div className={styles.success}>
      {displayTextsByEvent[latestEvent.type]}
    </div>
  )
})
