import { navigate } from "gatsby"
import React, { ReactElement, useState, useCallback } from "react"
import { Transaction } from "../../domain/models"
import { AuthError, ReservationService } from "../../domain/services"
import {
  CalculateCancellationFeeUseCase,
  CancelReservationUseCase,
} from "../../domain/use-cases"
import { AcceptEntryUseCase } from "../../domain/use-cases/accept-entry-use-case"
import { RejectEntryUseCase } from "../../domain/use-cases/reject-entry-use-case"
import { Button } from "../common/button"
import { Dialog } from "../common/dialog"
import { ParkingReservationInfoTable } from "../parking-reservation"
import { useTransaction } from "../useTransaction"
import * as styles from "./reservation-detail-container.module.scss"
import { TransactionWidget } from "./transaction-widget"
import "remixicon/fonts/remixicon.css"

const reservationService = new ReservationService()
const calculateCancellationFeeUseCase = new CalculateCancellationFeeUseCase(
  reservationService
)
const cancelReservationUseCase = new CancelReservationUseCase(
  reservationService
)
const acceptEntryUseCase = new AcceptEntryUseCase(reservationService)
const rejectEntryUseCase = new RejectEntryUseCase(reservationService)

export const ReservationContainer = (): ReactElement => {
  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [cancellationFee, setCancellationFee] = useState<number>()

  const { transaction, fetchTransaction } = useTransaction({
    fallback: async e => {
      if (e instanceof AuthError) {
        await navigate("/signin", { replace: true })
      }
    },
    polling: true,
  })

  const handlePreCancelClick = useCallback(async (transaction: Transaction) => {
    setCancelModalOpen(true)

    try {
      const result = await calculateCancellationFeeUseCase.call({
        transactionName: transaction.name,
      })

      if (result.fee == null) {
        // TODO: FIX ME
        alert("キャンセル料金の取得に失敗しました。")
        return
      }

      setCancellationFee(Number(result.fee.units))
    } catch (e) {
      if (e instanceof AuthError) {
        await navigate("/signin", { replace: true })
      }
    }
  }, [])

  const handleReservationRevokeClick = useCallback(
    async (transaction: Transaction) => {
      try {
        const result = await cancelReservationUseCase.call({
          transactionName: transaction.name,
        })

        if (result.transaction == null) {
          // TODO: FIX ME
          alert("予約のキャンセルに失敗しました。")
          return
        }

        setCancelModalOpen(false)
        await fetchTransaction()
      } catch (e) {
        if (e instanceof AuthError) {
          await navigate("/signin", { replace: true })
        }
      }
    },
    []
  )

  const handleEntryAcceptClick = useCallback(
    async (transaction: Transaction) => {
      try {
        const result = await acceptEntryUseCase.call({
          transactionName: transaction.name,
        })
        if (!result) {
          // TODO: FIX ME
          alert("入庫確認に失敗しました。")
          return
        }

        await navigate("/reservation-success", {
          replace: true,
        })
      } catch (e) {
        if (e instanceof AuthError) {
          await navigate("/signin", { replace: true })
        }
      }
    },
    []
  )

  const handleEntryRejectClick = useCallback(
    async (transaction: Transaction) => {
      try {
        const result = await rejectEntryUseCase.call({
          transactionName: transaction.name,
        })
        if (!result) {
          // TODO: FIX ME
          alert("入庫確認に失敗しました。")
          return
        }

        await navigate("/reservation-failure", {
          replace: true,
        })
      } catch (e) {
        if (e instanceof AuthError) {
          await navigate("/signin", { replace: true })
        }
      }
    },
    []
  )

  const handleReserveAgainClick = useCallback(
    async (transaction: Transaction) => {
      if (!transaction.parking) {
        await navigate("/", { replace: true })
        return
      }

      await navigate(`/${transaction.parking.name}/reservation`)
    },
    []
  )

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>予約ステータス</h3>

      <button className={styles.refresh} onClick={() => fetchTransaction()}>
        ステータスを更新 <i className="ri-restart-line ri-xl"></i>
      </button>

      {transaction == null ? (
        <p>予約はありません。</p>
      ) : (
        <>
          <TransactionWidget transaction={transaction}>
            <div className={styles.actionButtons}>
              {transaction.latestEvent?.isReserved && (
                <ReservedAction
                  onRevoke={() => handlePreCancelClick(transaction)}
                />
              )}
              {transaction.latestEvent?.requiresAction && (
                <EntryDetectedAction
                  onEntryAccept={() => handleEntryAcceptClick(transaction)}
                  onEntryReject={() => handleEntryRejectClick(transaction)}
                />
              )}
              {transaction.latestEvent?.isConcluded && (
                <ReReserveAction
                  onReserveAgain={() => handleReserveAgainClick(transaction)}
                />
              )}
            </div>
          </TransactionWidget>

          {transaction.parking && (
            <ParkingReservationInfoTable parking={transaction.parking} />
          )}

          <Dialog
            open={isCancelModalOpen}
            title="予約を削除しますか？"
            confirmText="削除"
            cancelText="キャンセル"
            onConfirm={() => handleReservationRevokeClick(transaction)}
            onCancel={() => setCancelModalOpen(false)}
            danger={true}
          >
            <p>
              予約削除には、予約時刻から削除までの時間に応じた駐車場料金（予約料金）がかかります。予約料金には最大料金が適用されません。
            </p>
            {cancellationFee != null && (
              <div className={styles.cancellationFee}>
                <span>{cancellationFee.toLocaleString("ja-JP")}</span>
                <span className={styles.feeSuffix}>円</span>
              </div>
            )}
          </Dialog>
        </>
      )}
    </div>
  )
}

const ReservedAction = ({
  onRevoke,
}: {
  onRevoke: () => void
}): ReactElement => {
  return (
    <Button variant="secondary" layout="fill" onClick={onRevoke}>
      予約を取消
    </Button>
  )
}

const EntryDetectedAction = ({
  onEntryAccept,
  onEntryReject,
}: {
  onEntryAccept: () => void
  onEntryReject: () => void
}): ReactElement => {
  return (
    <>
      <Button layout="fill" onClick={onEntryAccept}>
        入庫しました
      </Button>
      <Button variant="secondary" layout="fill" onClick={onEntryReject}>
        入庫していません
      </Button>
    </>
  )
}

const ReReserveAction = ({
  onReserveAgain,
}: {
  onReserveAgain: () => void
}): ReactElement => {
  return (
    <Button variant="secondary" layout="fill" onClick={onReserveAgain}>
      再予約
    </Button>
  )
}
