import { navigate } from "gatsby"
import React, { ReactElement } from "react"
import MediaQuery from "react-responsive"
import { Layout, SEO } from "../components"
import { ReservationContainer } from "../components/reservation-detail"
import * as config from "../config"
import AuthGuard from "../middleware/AuthGuard"
import * as styles from "./reservation-detail.module.scss"

const ReservationDetailPage = (): ReactElement => {
  return (
    <AuthGuard>
      <Layout>
        <SEO title="予約ステータス" lang="ja" />
        <div className={styles.mainContainer}>
          <MediaQuery minWidth={config.RESPONSIVE_BREAKPOINT}>
            <button
              className={styles.backToTopButton}
              onClick={() => navigate("/")}
            >
              検索画面へ戻る
            </button>
          </MediaQuery>
          <ReservationContainer />
        </div>
      </Layout>
    </AuthGuard>
  )
}

export default ReservationDetailPage
